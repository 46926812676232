import React from 'react';
import { Link } from 'gatsby';

const BlogCategories = () => (
  <div className="articles-categories-list">
    <Link to="/articles/category/understanding-land-surveys">Understanding Land Surveys</Link>
    <Link to="/articles/category/real-property-reports-compliance">
      Real Property Reports (RPRs) & Compliance
    </Link>
    <Link to="/articles/category/construction-surveys-planning">
      Construction Surveys & Planning
    </Link>
    <Link to="/articles/category/property-boundaries-rights">Property Boundaries & Rights</Link>
    <Link to="/articles/category/condominium-ownership">Condominium Ownership</Link>
    <Link to="/articles/category/utilities-easements">Utilities & Easements</Link>
  </div>
);

export default BlogCategories;
