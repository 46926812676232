import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';
import BlogCategories from '../../../components/BlogCategories';

const ConstructionSurveysPlanning = () => (
  <Layout
    title="Articles about surveying and RPR (Real Property Report) requirements relating to municipal bylaws permits and related issues."
    description="Want to learn more about the bylaws in Calgary, Red Deer and Edmonton and how they effect an RPR / Real Property Reports? This a in depth blog style resource to help you understand the details of the many permit and by-law issues."
    keywords=""
  >
    <main>
      <BlogCategories />

      <h1>Category: Construction Surveys & Planning</h1>
      <ul>
        <li>
          <Link to="/articles/what-is-a-plot-plan">What Is a Plot Plan</Link>
          <br />A plot plan is a detailed technical drawing or map that provides an accurate
          representation of a property’s boundaries, structures, natural features, legal
          restrictions, and other relevant information. It serves as a foundational document for
          various real estate and construction activities by ensuring clarity in ownership,
          compliance with regulations, and efficient project planning.
        </li>
      </ul>
    </main>
  </Layout>
);

export default ConstructionSurveysPlanning;
